import TagManager from 'react-gtm-module'
import { MouseEvent } from 'react'

import { AnalyticsContext } from '../types/analytics'
import { getClickType } from '../event-utils'

interface ProductCardClickParams {
  sku: string
  context: AnalyticsContext
  event: MouseEvent<Element>
}

const productCardClickEvent = ({ sku, context, event }: ProductCardClickParams) => {
  const clickType = getClickType(event)

  TagManager.dataLayer({
    dataLayer: {
      event: 'product_card_click',
      product_id: sku,
      context,
      click_type: clickType,
    },
  })
}

export default productCardClickEvent
